import { call, put } from "redux-saga/effects"
import { getBackerFailed, getBackerSuccess } from "../actions"
import { backervoiceApi } from "../apis"

export default function* handleGetBacker({ payload }) {
  try {
    const data = yield call(backervoiceApi.getBacker, payload.id)

    yield put(getBackerSuccess(data))
  } catch (e) {
    yield put(getBackerFailed(e))
  }
}
