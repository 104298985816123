import axios from "axios"
import ApiUrl from "../utils/api"

export class HttpClient {
  constructor() {
    HttpClient.instance = axios.create({ baseURL: ApiUrl })

    return this
  }

  getInstance() {
    return HttpClient.instance
  }
}

export default new HttpClient()
