import {
  GET_BACKER_SUCCESS,
  GET_GOOGLE_CONVERSIONS_SUCCESS,
  GET_META_CONVERSIONS_SUCCESS,
} from "../actions/consts"

const initialState = {
  backer: null,
  conversions: null,
  metaConversions: null,
}

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_BACKER_SUCCESS:
      return { ...state, backer: payload.data }
    case GET_GOOGLE_CONVERSIONS_SUCCESS:
      return { ...state, conversions: payload.data.data }
    case GET_META_CONVERSIONS_SUCCESS:
      return { ...state, metaConversions: payload.data }

    default:
      return state
  }
}

export const getProjects = state => state.projects
export const getProjectsBacker = state => getProjects(state).backer
export const getProjectsGoogleConversions = state =>
  getProjects(state).conversions
export const getProjectsMetaConversions = state =>
  getProjects(state).metaConversions || []
