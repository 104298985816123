/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

const React = require("react")
const Bugsnag = require("@bugsnag/js").default
const BugsnagPluginReact = require("@bugsnag/plugin-react").default
const { Provider } = require("react-redux")
const createStore = require("./configure-store").default
const ConsentBanner = require("./src/components/ConsentBanner").default
const { setupConsent } = require("./src/utils/googletag-manager")

Bugsnag.start({
  apiKey: process.env.GATSBY_BUGSNAG_API_KEY || "no-key-defined",
  plugins: [new BugsnagPluginReact()],
  releaseStage: process.env.GATSBY_ENV,
  enabledReleaseStages: ["production", "staging"],
})

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React)

export const wrapRootElement = ({ element }) => {
  const store = createStore()

  return (
    <ErrorBoundary>
      <Provider store={store}>
        {element}
        <ConsentBanner />
      </Provider>
    </ErrorBoundary>
  )
}

export const onClientEntry = () => {
  setupConsent()
}
