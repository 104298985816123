import styled from "@emotion/styled/macro"

export const Banner = styled.div`
  display: ${props => (props.isVisible ? "block" : "none")};
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #f8f9fa;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  color: black;
  padding: 15px;
  font-size: 14px;
  text-align: center;
  z-index: 1000;
`

const handleColorType = color => {
  switch (color) {
    case "success":
      return "background-color: #34a853; color: white;"
    case "outline":
      return "background-color: #e6f4ea; color: #34a853;"
    default:
      return "background-color: #dfe1e5; color: black;"
  }
}

export const Button = styled.button`
  border: none;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;

  &:hover {
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);
  }

  &:active {
    opacity: 0.5;
  }

  ${({ color }) => handleColorType(color)};
`

export const Options = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 10px;

  & label {
    margin: 0 10px;
    font-size: 14px;
  }

  & input {
    display: inline-block;
    margin-right: 5px;
  }
`
