import { all, takeEvery, takeLatest } from "redux-saga/effects"
import {
  GET_BACKER,
  GET_GOOGLE_CONVERSIONS,
  GET_META_CONVERSIONS,
} from "../actions/consts"
import handleGetBacker from "./get-backer"
import handleGetGoogleConversions from "./get-google-conversions"
import handleMetaConversion from "./meta-conversion"

export default function* sagas() {
  yield all([
    takeEvery(GET_BACKER, handleGetBacker),
    takeEvery(GET_GOOGLE_CONVERSIONS, handleGetGoogleConversions),
    takeLatest(GET_META_CONVERSIONS, handleMetaConversion),
  ])
}
