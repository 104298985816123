export function isLocalStorageAvailable() {
  try {
    localStorage.setItem("__test__", "__test__")
    localStorage.removeItem("__test__")
    return true
  } catch (e) {
    return false
  }
}

export const storageWrapper = isLocalStorageAvailable()
  ? localStorage
  : {
      getItem: () => null,
      setItem: () => null,
    }
