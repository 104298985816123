import { createStore, combineReducers, applyMiddleware, compose } from "redux"
import createSagaMiddleware from "redux-saga"
import projects from "./src/reducers/projects"
import sagas from "./src/sagas"

const isDev =
  process.env.NODE_ENV === "local" || process.env.NODE_ENV === "development"

const configureStore = initialState => {
  const windowGlobal = typeof window !== "undefined" && window

  const devtools =
    isDev && windowGlobal
      ? window.__REDUX_DEVTOOLS_EXTENSION__ &&
        window.__REDUX_DEVTOOLS_EXTENSION__()
      : f => f

  const reducers = combineReducers({ projects })
  const sagaMiddleware = createSagaMiddleware()

  const store = createStore(
    reducers,
    initialState,
    compose(applyMiddleware(sagaMiddleware), devtools)
  )

  sagaMiddleware.run(sagas)

  return store
}

export default configureStore
