import { checkIsProd } from "../utils/checkEnv"

const isProdEnvironment = checkIsProd()

const api = "api.backercamp.com"
const version = "/api/v3"

export default isProdEnvironment
  ? `https://${api}${version}`
  : `https://staging-${api}${version}`
