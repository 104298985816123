import { all, call, put } from "redux-saga/effects"
import { getMetaConversionsFailed, getMetaConversionsSuccess } from "../actions"
import { backervoiceApi } from "../apis"

export default function* handleMetaConversion({ payload }) {
  try {
    const [purchaseData, pageViewData] = yield all([
      call(backervoiceApi.purchaseMetaConversion, payload.id, payload.data),
      call(backervoiceApi.pageViewMetaConversion, payload.id, payload.data),
    ])

    yield put(getMetaConversionsSuccess([...purchaseData, ...pageViewData]))
  } catch (e) {
    yield put(getMetaConversionsFailed(e))
  }
}
