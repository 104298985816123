import { errorHandler } from "./utils"

export default client => {
  const getBacker = id =>
    client
      .get(`backervoice/backer/${id}`)
      .then(({ data }) => data)
      .catch(errorHandler)

  const getConversions = uuid =>
    client
      .post(`google/conversions/conversion-action/${uuid}`)
      .then(({ data }) => data)
      .catch(errorHandler)

  const purchaseMetaConversion = (uuid, data) =>
    client
      .post(`facebook/conversions/event/purchase/${uuid}`, data)
      .then(({ data }) => data.data)
      .catch(errorHandler)

  const pageViewMetaConversion = (uuid, data) =>
    client
      .post(`facebook/conversions/event/pageview/${uuid}`, data)
      .then(({ data }) => data.data)
      .catch(errorHandler)

  return {
    getBacker,
    getConversions,
    purchaseMetaConversion,
    pageViewMetaConversion,
  }
}
