import React, { useState } from "react"
import { Banner, Button, Options } from "./styled"
import { setConsent, shouldDisplayBanner } from "../../utils/googletag-manager"

const Checkbox = ({ disabled, label, name, checked, onChange }) => {
  return (
    <label>
      <input
        type="checkbox"
        name={name}
        checked={checked}
        disabled={disabled}
        onChange={onChange}
      />
      {label}
    </label>
  )
}

const ConsentBanner = () => {
  const [visible, setVisible] = useState(shouldDisplayBanner())

  const [checkboxState, setCheckboxState] = useState({
    necessary: true,
    analytics: false,
    preferences: false,
    marketing: true,
  })

  const handleChange = event => {
    setCheckboxState({
      ...checkboxState,
      [event.target.name]: event.target.checked,
    })
  }

  const handleAcceptAll = () => {
    setConsent({
      necessary: true,
      analytics: true,
      preferences: true,
      marketing: true,
    })
    setVisible(false)
  }

  const handleAcceptSelection = () => {
    setConsent(checkboxState)
    setVisible(false)
  }

  const handleRejectAll = () => {
    setConsent({
      necessary: false,
      analytics: false,
      preferences: false,
      marketing: false,
    })
    setVisible(false)
  }

  return (
    <Banner isVisible={visible} id="cookie-consent-banner">
      <h3>Cookie settings</h3>
      <p>
        We use cookies to provide you with the best possible experience. They
        also allow us to analyze user behavior in order to constantly improve
        the website for you.
      </p>
      <Button id="btn-accept-all" color="success" onClick={handleAcceptAll}>
        Accept All
      </Button>
      <Button
        id="btn-accept-some"
        color="outline"
        onClick={handleAcceptSelection}
      >
        Accept Selection
      </Button>
      <Button id="btn-reject-all" color="grayscale" onClick={handleRejectAll}>
        Reject All
      </Button>
      <Options>
        <Checkbox
          name="necessary"
          type="checkbox"
          label="Necessary"
          checked={checkboxState.necessary}
          onChange={handleChange}
        />
        <Checkbox
          name="analytics"
          type="checkbox"
          label="Analytics"
          checked={checkboxState.analytics}
          onChange={handleChange}
        />
        <Checkbox
          name="preferences"
          type="checkbox"
          label="Preferences"
          checked={checkboxState.preferences}
          onChange={handleChange}
        />
        <Checkbox
          name="marketing"
          type="checkbox"
          label="Marketing"
          checked={checkboxState.marketing}
          onChange={handleChange}
        />
      </Options>
    </Banner>
  )
}

export default ConsentBanner
